import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useColorMode, colors } from '../ColorModeProvider';
import Markdown from 'markdown-to-jsx'; // Import markdown-to-jsx
import VoiceText from './VoiceText';

interface MessageBubbleProps {
    language: string;
    message: string;
    isSentByUser?: boolean; // Optional prop to differentiate user and received messages
}

const MessageBubble: React.FC<MessageBubbleProps> = ({ message, language = 'en-US', isSentByUser = true }) => {
    const theme = useTheme();
    const { mode } = useColorMode();
    const isDarkMode = mode === "dark";

    // Custom renderers for both images and videos
    const renderers = {
        img: ({ src, alt }: { src?: string; alt?: string }) => (
            <Box
                component="img"
                src={src}
                alt={alt}
                sx={{
                    maxWidth: '100%',
                    borderRadius: '8px',
                    marginTop: '8px'
                }}
            />
        ),
        // Custom renderer for videos
        video: ({ src }: { src?: string }) => (
            <Box
                component="video"
                controls
                src={src}
                sx={{
                    maxWidth: '100%',
                    borderRadius: '8px',
                    marginTop: '8px'
                }}
            />
        ),
        // Handle links to render videos from markdown like `[video](video-url)`
        a: ({ href, children }: { href?: string; children?: React.ReactNode }) => {
            const isVideo = href && (href.endsWith('.mp4') || href.includes('youtube.com') || href.includes('vimeo.com'));
            if (isVideo) {
                return (
                    <Box
                        component="iframe"
                        src={href}
                        sx={{
                            maxWidth: '100%',
                            borderRadius: '8px',
                            marginTop: '8px',
                            height: '300px',
                        }}
                    />
                );
            }
            return <a href={href}>{children}</a>;
        }
    };

    const removeImagesAndVideos = (text: string) => {
        return text.replace(/!\[.*?\]\(.*?\)|\[.*?\]\((.*?\.(mp4|youtube\.com|vimeo\.com))\)|\b\S+\.(png|jpg|jpeg|gif)\)?/g, '');
    };

    return (
        <Box
            sx={{
                position: 'relative',
                padding: '8px 12px',
                borderRadius: '16px',
                maxWidth: '95%',
                margin: '4px 0',
                alignSelf: isSentByUser ? 'flex-end' : 'flex-start',
                backgroundColor: isSentByUser
                    ? isDarkMode ? colors.grey[600] : '#f1f1f1'
                    : 'none',
                color: theme.palette.text.primary,
                wordWrap: 'break-word',
                whiteSpace: 'pre-wrap',
            }}
        >
            {isSentByUser
                ? <Typography variant="body1" component="div" sx={{ fontSize: 16 }}>
                    {message}
                </Typography>
                :
                <Markdown
                    options={{
                        overrides: renderers, // Attach the custom renderers for images and videos
                    }}
                    style={{ fontSize: 16 }}
                >
                    {message}
                </Markdown>
            }
            {!isSentByUser && <Box sx={{ position: 'absolute', left: 0, bottom: -12 }}>
                <VoiceText text={removeImagesAndVideos(message)} language={language} />
            </Box>}
        </Box>
    );
};

export default MessageBubble;
